import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FriendlyUrlService } from "../../services/friendly-url.service";

@Component({
  selector: "app-live-preview-btn",
  templateUrl: "./live-preview-btn.component.html",
  styleUrls: ["./live-preview-btn.component.scss"],
})
export class LivePreviewBtnComponent implements OnInit, OnChanges {
  @Input() type: string = "";
  @Input() itemId: string = "";
  @Input() isItemValid: string = "";
  @Input() item: any = null;

  routeBase: string = "";

  constructor(
    private router: Router,
    private friendlyUrlService: FriendlyUrlService
  ) {}

  ngOnInit(): void {
    this.updateRouteBase();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.item || changes.itemId || changes.type) {
      // console.log("Inputs changed:", changes);
      this.updateRouteBase();
    }
  }

  private updateRouteBase(): void {
    if (this.item) {
      switch (this.type) {
        case "sp":
          this.routeBase = this.friendlyUrlService.routeToBranchDetails(
            "branch/details",
            this.item
          );
          break;
        case "trips":
          this.routeBase = this.friendlyUrlService.routeToTripDetails(
            "trips/tripDetails",
            this.item
          );
          break;
        case "courses":
          this.routeBase = this.friendlyUrlService.routeToCourcesDetails(
            "trips/course",
            this.item
          );
          break;
        case "liveaboard-trip":
          this.routeBase = this.friendlyUrlService.routeToLiveaTripDetails(
            "trips/liveaboardDetails",
            this.item
          );
          break;
        default:
          console.warn(`Unknown type: ${this.type}`);
          break;
      }
    } else {
      const originBase = window.location.origin;
      this.routeBase = `${originBase}/#/${this.type}/${this.itemId}`;
    }
  }

  livePreview(): void {
    this.updateRouteBase();
    // console.log("Live Preview URL:", this.routeBase);
  }
}
